import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, financeiroStore, plugnotasStore } from '@/store';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PixIntegradorExibirDetalhesPixDialog from '@/components/PixIntegradorExibirDetalhesPixDialog.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { downloadZip } from '@/utils';
import { differenceInDays } from 'date-fns';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.radioGroup = 'V';
        this.loading = false;
        this.search = '';
        this.selectPeriodDate = null;
        this.loadingDownloadXmls = false;
        this.plugnotasIds = [];
        this.selectAllCheckbox = false;
    }
    get headers() {
        const array = [
            { text: '', value: 'selected', sortable: false },
            { text: 'Vencimento', value: 'data_vencimento' },
            { text: 'Emissão', value: 'plugnotas_data_emissao' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Valor Integral', value: 'valor_integral' },
            { text: 'Total Pago', value: 'total_pago' },
            { text: 'Observação', value: 'observacao' },
            { text: 'Livro', value: 'nome_livro' },
            { text: 'NF', value: 'plugnotas_id' }
        ];
        return array;
    }
    selectAll(value) {
        this.getNFCEs.forEach((item) => {
            if (item.plugnotas_id && item.plugnotas_status === 'CONCLUIDO') {
                item.selected = value;
            }
        });
    }
    checkSelected() {
        this.selectAllCheckbox = this.getNFCEs.some((i) => i.selected);
    }
    get getNFCEs() {
        return financeiroStore.financeirosMateriaisDidatico.map((item) => {
            return { ...item, selected: false };
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async buscar() {
        this.loading = true;
        await financeiroStore.getFinanceirosMaterialDidatico({
            dataInicial: this.selectPeriodDate[0],
            dataFinal: this.selectPeriodDate[1],
            tipoDocumento: 2,
            tipoPesquisa: this.radioGroup
        });
        this.loading = false;
    }
    async handleGerarXmlPeriodo() {
        const differenceDays = differenceInDays(new Date(this.selectPeriodDate[1]), new Date(this.selectPeriodDate[0]));
        if (differenceDays > 31) {
            this.$swal({
                title: 'Erro ao gerar nesse intervalo',
                text: 'Somente é possível gerar as notas no periodo máximo de 31 dias',
                icon: 'error',
                showCancelButton: false
            });
            return;
        }
        this.loadingDownloadXmls = true;
        const responseData = await plugnotasStore.gerandoArquivoXmlPeriodo({ tipoNota: 'nfce', dataInicial: this.selectPeriodDate[0], dataFinal: this.selectPeriodDate[1] });
        this.loadingDownloadXmls = false;
        const nomeArquivoGerado = `${this.selectPeriodDate[0]}_${this.selectPeriodDate[1]}_NFCE`;
        if (responseData) {
            downloadZip(nomeArquivoGerado, responseData);
        }
    }
};
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            PixIntegradorExibirDetalhesPixDialog,
            PlugnotasConsultaEmiteButton,
            OpenNewTabComponent
        }
    })
], List);
export default List;
